<template>
  <div class="jf-action">
    <van-button type="primary" block @click="onJfBtnClick" size="small">
      <slot>缴费</slot>
    </van-button>
    <van-action-sheet v-model="show" title="缴费" position="bottom" class="jf-action-dialog" close-on-popstate
      close-on-click-overlay @close="doClose">
      <van-cell-group>
        <van-cell :border="false" title="户名:" :value="sourceData.yhmc" />
        <van-cell :border="false" title="户号:" :value="sourceData.yhbh" />
        <van-cell :border="false" title="欠电费:" :value="sourceData.dqqf | currency" />
        <van-cell :border="false" title="预付余额：" :value="sourceData.dqjy | currency" />
        <van-cell :border="false" title="应收违约金:" :value="sourceData.yswyj | currency" />
        <van-cell :border="false" title="合计金额:" :value="yjhj | currency" />
      </van-cell-group>
      <divider-box size="8" />
      <van-form @submit="onSubmit" style="margin: 16px 0">
        <van-field type="text" label="实缴金额：" placeholder="" v-model="jfje" />
        <p style="font-size: 14px; color: #999; margin: 10px 16px;">最小缴费金额：<span
            style="color: red;">{{yjje_L | currency}}</span></p>
        <div style="margin: 16px; margin-top: 32px;">
          <van-button block type="primary" native-type="submit">立即缴费 {{jfje | currency}}</van-button>
        </div>
      </van-form>

    </van-action-sheet>
  </div>
</template>

<script>

  import wxPay from '@/utils/wx-pay';
  import { getOpenId, setOpenId } from '@/utils/auth';

  import Decimal from '@/vendor/decimal';

  import { ydzfService_wxzf } from '@/api/dfzw';
  import {
    counterFeesService_getWxgzhYhqfsj
  } from '@/api/dfcj';
  export default {
    name: 'JfAction',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      sourceData: {
        type: Object,
        default: () => {
          return {}
        }
      },
      isTyjs: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        openId: null,
        show: false,
        jfje: 0,
        yjje_L: 0
      }
    },
    computed: {
      yjhj() {
        const {
          yswyj,
          dqqf,
          dqjy
        } = this.sourceData;
        let amount = yswyj + dqqf - dqjy;
        if (amount < 0) {
          amount = 0;
        }
        return amount;
      }
    },
    methods: {
      doClose() {
        this.show = false;
        this.$emit('close')
      },
      onJfBtnClick() {
        // TODO： 缴费前查询欠费信息
        this.$toast.loading({
          message: '处理中',
          duration: 0
        });
        const {yhbh, zwyf} = this.sourceData;
        counterFeesService_getWxgzhYhqfsj(yhbh).then(res => {
          this.$toast.clear();
          if (res.content.status == 200) {
            let items = res.content.data.yhqfmx;
            let canContinue = false;
            for(let [i,v] of items.entries()) {
              if (v.zwyf == zwyf && v.dqqf && v.dqqf > 0) {
                canContinue = true;
                break;
              }
            }
            if (canContinue) {
              let yswyj = new Decimal(this.sourceData.yswyj);
              let dqqf = new Decimal(this.sourceData.dqqf);
              this.yjje_L = yswyj.add(dqqf).toNumber();
              this.jfje = yswyj.add(dqqf).toNumber();
              this.show = true;
            } else {
              this.$toast('当前账务月费用已缴清！');
              this.$emit('refresh');
            }
          } else {
            throw new Error(res.content.message);
          }
        }).catch(err => {
          this.$toast('欠费信息查询失败:'+ err.message);
        });
      },
      onSubmit() {
        // Todo: 调用微信支付SDK
        if (this.jfje < this.yjje_L) {
          return this.$toast('实缴金额不能小于 ¥'+this.yjje_L)
        }
        let prePayInfo = {
          czyh: this.$conf.DEFAULT_PAY_CZYH,
          jgbm: this.sourceData.jgbm,
          yhbh: this.sourceData.yhbh,
          zwyf: this.sourceData.zwyf,
          jfje: parseFloat(this.jfje), //实缴金额，可编辑的input
          isTyjs: this.isTyjs,
          xzbz: '0', // 现转标志： 0-现金, 1-转账
          zflx: '2', // 支付类型： 1-预付款存入,  2-缴费
          openid: this.openId
        };

        this.$toast.loading({
          message: '正在缴费...',
          duration: 0,
          mask:true,
          forbidclick:true
        });

        ydzfService_wxzf(prePayInfo).then(res => {
          if (res.status == 200) {
            let payInfo = res.data;
            wxPay(payInfo, function(res) {
              this.$toast.success('缴费成功');
              this.$router.push({
                path: `/dfcj/${this.sourceData.yhbh}/result`, 
                query: prePayInfo
              });
            },function (err) {
              this.$toast.fail(err.message);
            },this);
          } else {
            this.$toast(res.message);
          }
        }).catch(err => {
          this.$toast.fail('缴费失败')
        });
      }
    },
    mounted() {
      this.openId = getOpenId();
    },

  }
</script>

<style lang="scss" scoped>
  .jf-action {
    display: inline-block;
  }
</style>

<style lang="scss">
  .jf-action-dialog {
    .van-field__control {
      font-size: 20px;
      color: #000;
    }
  }
</style>